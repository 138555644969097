<template>
  <div>
    <v-tabs v-model="view" :value="currView" slider-color="white">
      <v-tab key="personal" @click="changeTab('personal')">
        <span class="text--white font-regular">Personal</span>
      </v-tab>
      <v-tab key="business" @click="changeTab('business')">
        <span class="text--white font-regular">Business</span>
      </v-tab>
    </v-tabs>
  </div>
</template>
<script>
export default {
  props: ['changeTab', 'currView'],
  data() {
    return {
      view: this.currView
    };
  }
};
</script>
